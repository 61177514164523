<template>
    <unauthorized
        v-if="!!notFound || $store.getters.unauthorized.includes('/secure/' + (!routesName ? modelsName : routesName) + '/form')"
    ></unauthorized>

    <h-row v-else-if="!isLoading" justify="center">

        <loading-resouces v-show="!!isSavingLoading" icon="fa-save" messaggio="Salvataggio in corso"></loading-resouces>

        <loading-resouces v-show="!!isResoucesLoading"
                          icon="fa-cubes"
                          messaggio="Recupero delle informazioni in corso"
        ></loading-resouces>

        <h-col v-show="!isSavingLoading && !isResoucesLoading" cols="11" class="mt-6 px-0">
            <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
                <v-card>

                    <v-card-title class="pa-0">
                        <h-row class="warning" justify="space-between">
                            <h-col v-for="(t, index) in tabs" :key="index" :href="'#' + t.name" v-show="t.showIf" no-padding>
                                <v-btn v-if="t.showIf"
                                       text
                                       block
                                       large
                                       height="72"
                                       :color="panel === t.name ? 'white' : 'black'"
                                       @click="panel = t.name"
                                >
                                    <h-row>
                                        <h-col class="pb-2" cols="12" no-padding>
                                            <v-icon>{{ t.icon }}</v-icon>
                                        </h-col>
                                        <h-col cols="12" no-padding>
                                            <span>{{ t.title }}</span>
                                        </h-col>
                                    </h-row>
                                </v-btn>
                            </h-col>
                        </h-row>
                    </v-card-title>

                    <h-row class="mt-6"
                           justify="center"
                           v-for="(t, index) in tabs"
                           :key="index"
                           v-show="t.showIf && panel === t.name"
                    >
                        <h-col v-if="t.showIf" cols="12" :tab="t.name">

                            <slot :name="t.name"
                                  :tab="t"
                                  :valid="valid"
                                  :model="model"
                                  :form="$refs.form"
                                  :isGlobalLoading="isGlobalLoading"
                            ></slot>

                        </h-col>
                    </h-row>

                    <v-divider></v-divider>

                    <h-row class="pa-3" justify="end">
                        <h-col cols="auto">
                            <button-tooltip large
                                            icon-name="fa-times"
                                            text="ANNULLA"
                                            text-margin="3"
                                            color="error"
                                            min-width="200"
                                            :disabled="!!isGlobalLoading"
                                            :to="'/secure/' + (!routesName ? modelsName : routesName) + (!model.ID_REC ? '' : '/scheda?id=' + model.ID_REC )"
                                            exact
                                            tooltip="Annulla"
                            ></button-tooltip>
                        </h-col>
                        <h-col cols="auto">
                            <button-tooltip large
                                            icon-name="fa-save"
                                            text="SALVA"
                                            text-margin="3"
                                            color="success"
                                            min-width="200"
                                            type="submit"
                                            :disabled="!!isGlobalLoading"
                                            tooltip="Salva"
                            ></button-tooltip>
                        </h-col>
                    </h-row>

                </v-card>
            </v-form>

            <v-dialog v-if="dialog"
                      v-model="dialog"
                      persistent
                      max-width="400px"
                      @click:outside.stop="dialog = false"
                      @keydown.esc="dialog = false"
            >
                <v-card>

                    <v-card-title>
                        <h-row justify="space-between">
                            <h-col no-padding>
                                <h-row justify="center">
                                    <h-col cols="auto" no-padding>
                                        <h3>ERRORE</h3>
                                    </h-col>
                                </h-row>
                            </h-col>
                            <h-col cols="auto" no-padding>
                                <v-btn icon color="error" @click.stop="dialog = false">
                                    <v-icon>fa-times</v-icon>
                                </v-btn>
                            </h-col>
                        </h-row>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text class="mt-4">
                        <h-row justify="center">
                            <h-col class="py-2" cols="auto" no-padding>
                                <span>Alcuni campi risultano errati, si prega di ricontrollare:</span>
                                <ul class="mt-3">
                                    <li v-for="(i, index) in inputs" :key="index" v-show="i.hasError">
                                        <i>{{ i.errorBucket[0] }}</i>
                                    </li>
                                </ul>
                            </h-col>
                        </h-row>
                    </v-card-text>

                </v-card>
            </v-dialog>
        </h-col>
    </h-row>

    <loading-resouces v-else icon="fa-cubes" messaggio="Recupero delle informazioni in corso"></loading-resouces>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import Unauthorized from '@/components/aaaGenerics/commons/Unauthorized';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'BaseForm',
        props: {modelsName: String, routesName: String, newModel: Object, isResoucesLoading: Boolean, tabs: Array},
        components: {HRow, HCol, Unauthorized, LoadingResouces, ButtonTooltip},
        data: () => ({
            isLoading: false,
            isSavingLoading: false,
            apiTimer: false,
            notFound: false,
            valid: false,
            dialog: false,
            panel: 'info',
            model: {}
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            id: function () {
                return this.$route.query.id;
            },
            inputs: function () {
                return !this.$refs.form ? [] : this.$refs.form.inputs;
            }
        },
        watch: {
            id: function () {
                this.initCheck();
            }
        },
        methods: {
            initCheck() {
                if (/^\d+$/.test(this.id)) {

                    if (this.$store.getters.unauthorized.includes(this.$route.path + '?id=')) {

                        this.isLoading = false;
                        this.notFound = true;

                    } else {

                        this.loadModel();

                    }

                } else {

                    if (this.$store.getters.unauthorized.includes(this.$route.path + '?')) {

                        this.isLoading = false;
                        this.notFound = true;

                    }

                }
            },
            loadModel() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_BASE_SINGLE',
                            paylod: {modelsName: _this.modelsName, id: _this.id},
                            doResponse: model => {
                                _this.model = model;
                                _this.model.DISATTIVAZIONE_MANUALE = !!model.DATA_DISATTIVAZIONE;
                                _this.$emit('endLoadModel', model);
                            },
                            doClose: () => {
                                _this.isLoading = false;
                                if (!_this.model.ID_REC) {
                                    _this.notFound = true;
                                }
                            }
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            },
            onSubmit() {
                this.dialog = !this.$refs.form.validate();
                if (this.valid) {
                    this.$emit('onSave', {model: this.model, save: this.onSave});
                } else {

                    const searchTab =
                        vComp => !vComp.$parent.$attrs.tab ? searchTab(vComp.$parent) : vComp.$parent.$attrs.tab;

                    for (const i of this.inputs) {
                        if (i.hasError) {

                            if (!i.$el.offsetParent) {
                                this.panel = searchTab(i);
                            }

                            return i.$el.scrollIntoView({behavior: "smooth", block: 'center'});
                        }
                    }

                }
            },
            onSave() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //POST/PUT del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: _this.model.ID_REC ? 'PUT_BASE' : 'POST_BASE',
                            paylod: {modelsName: _this.modelsName, model: _this.model, id: _this.id},
                            doResponse: model => {

                                _this.$store.dispatch('activeSnackbar', {
                                    color: 'success',
                                    text: 'Salvataggio avvenuto con successo'
                                });

                                _this.$router.push(
                                    '/secure/' +
                                    (!_this.routesName ? _this.modelsName : _this.routesName) +
                                    '/scheda?id=' + model.ID_REC
                                );

                            },
                            doClose: isOk => _this.isSavingLoading = isOk
                        });
                    }
                }

                this.isSavingLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            }
        },
        created() {
            this.model = this.newModel || {};
            this.panel = this.tabs[0].name;
            this.initCheck();
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
