<template>
    <base-form models-name="workspace" :tabs="tabs" :new-model="newModel" @onSave="onSave">

        <template #info="{model, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DISATTIVAZIONE_MANUALE"
                                       :items="[ {label: 'ATTIVO', value: false}, {label: 'DISATTIVATO', value: true} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Stato"
                                       placeholder="Seleziona se abilitare o disabilitare"
                                       hint="Decidi se abilitare o disabilitare manualmente il workspace"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-toggle-on"
                    ></base-select-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.NOME"
                                     label="Nome"
                                     placeholder="Digita il nome del workspace"
                                     hint="Il nome deve identificare il gruppo degli amministratori"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'Il nome è obbligatorio!']"
                                     prepend-icon="fa-signature"
                    ></base-text-field>
                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';

    export default {
        name: 'FormWorkspace',
        components: {HRow, HCol, BaseForm, BaseSelectField, BaseTextField},
        data: () => ({
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true}
            ],
            newModel: {
                DISATTIVAZIONE_MANUALE: false
            }
        }),
        methods: {
            onSave({model, save}) {
                save(model);
            }
        }
    }
</script>

<style scoped>

</style>
